import "./App.css"
import Ads from "./Components/Ads";
import { FaGithub } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
function App() {

  return (
    <>
    <header>
    <div className="logoo">
      <img src="/favicon.png" style={{width:"50%"}}></img>
    </div>
    <h1>Copywriter GPT</h1>
    <div className="icons">
      <div><FaGithub className="git"></FaGithub></div>
      <MdEmail className="email"></MdEmail>
    </div>
    </header>
    <Ads></Ads>
    </>
  );
}

export default App;
