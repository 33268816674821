import React, { useEffect, useState } from "react";
import { GoogleGenerativeAI } from "@google/generative-ai";
import { HarmBlockThreshold, HarmCategory } from "@google/generative-ai";

export default function Ads() {
  const safetySettings = [
    {
      category: HarmCategory.HARM_CATEGORY_HARASSMENT,
      threshold: HarmBlockThreshold.BLOCK_ONLY_HIGH,
    },
  ];

  const genAI = new GoogleGenerativeAI(process.env.REACT_APP_API_KEYY);
  const model = genAI.getGenerativeModel({ model: "gemini-pro", safetySettings });

  const [text, setText] = useState("");
  const [topic, setTopic] = useState("");
  const [objective, setObjective] = useState("");
  const [audience, setAudience] = useState("");
  const [action, setAction] = useState("");
  const [keywords, setKeywords] = useState("");
  const [info, setInfo] = useState("");
  const [length, setLength] = useState(200);
  const [tone, setTone] = useState("");
  const [showCursor, setShowCursor] = useState(true);
  const [buttonClicked, setButtonClicked] = useState(false); // State to track button click
  const [promptText,setPromptText] = useState("")

  useEffect(() => {
    const Text = `Make a marketing copywritten text on title ${topic} , where objective of the marketing campaign is ${objective} and the campaign 
      is geared towards the target audience as defined ${audience || "null"} according to the given requirements and if the requirements are marked as null then generate on your own based on the topic , objective and target audience : 
      Call to action : ${action || "null"} , Keywords : ${
      keywords || "Generate on the basis of Latest Trends keeping the objective in mind"
    } ,
      Additional Info regarding the campaign or product description is : ${
        info || "whatever fits the context and keeps the objective in mind"
      } and keep the length limited to ${length} . Also the tone should be ${
      tone || "the best fit on the basis of context"
    } if any parameter is null think on your own based on the context`;
    setPromptText(Text);
  }, [topic, objective, audience, action, keywords, info, length, tone]);

  async function handleLLM(promptText) {
    if (!topic || !objective) {
      alert("Topic or Objective cannot be Empty");
      return;
    }

    setButtonClicked(true); // Set buttonClicked to true when clicked

    setShowCursor(true); // Show cursor when generating content
    setText("Getting Response ...");
    try {
      setText("Trying...");
      const result = await model.generateContentStream(promptText);
      let newText = "";
      for await (const chunk of result.stream) {
        const chunkText = chunk.text();
        newText += chunkText;
        setText(newText);
      }
    } catch (error) {
      setText("Error Occured , Try Again");
      console.log(error);
    } finally {
      setShowCursor(false); // Hide cursor when content generation is complete
    }
  }

  return (
    <div className="main">
      {!buttonClicked && (
        <div className="inputs">
          <label style={{ color: "#FFBAF7" }}>Product Name:*</label>
          <textarea type="text" placeholder="Topic" required={true} onChange={(e) => setTopic(e.target.value)} value={topic} />
          <label style={{ color: "FFBAF7" }}>Objective*:</label>
          <textarea type="text" placeholder="Objective" onChange={(e) => setObjective(e.target.value)} required={true} value={objective} />
          <label>Audience:</label>
          <textarea type="text" placeholder="Target Audience" onChange={(e) => setAudience(e.target.value)} value={audience} />
          <label>Call to Action:</label>
          <textarea type="text" placeholder="Call to Action" onChange={(e) => setAction(e.target.value)} value={action} />
          <label>Kewords(separated by comma):</label>
          <textarea type="text" placeholder="Keywords" onChange={(e) => setKeywords(e.target.value)} value={keywords} />
          <label>Additional Info / Product Description:</label>
          <textarea type="text" placeholder="Additional Info" onChange={(e) => setInfo(e.target.value)} value={info} />
          <label>Tone:</label>
          <textarea type="text" placeholder="Tone" onChange={(e) => setTone(e.target.value)} value={tone} />
          <label>Length of Generation:</label>
          <input type="number" placeholder="length" onChange={(e) => setLength(e.target.value)} value={length} />
        </div>
      )}

      {!buttonClicked && (
        <>
          <br />
          <button onClick={() => handleLLM(promptText)}>Generate</button>
        </>
      )}
      <br />
      <br />
      <p className="typing-animation" style={{ position: "relative", display: "inline-block" }}>
        {text.split("\n\n").map((paragraph, index) => (
          <React.Fragment key={index}>
            {paragraph.trim()}
            <br />
            <br />
          </React.Fragment>
        ))}
        {showCursor && <span style={{ position: "absolute", right: 0, top: 0, width: "5px" }}>&#x2588;</span>}
      </p>
      {buttonClicked && (<button onClick={()=>{setButtonClicked(false)}}>Retry</button>)}
    </div>
  );
}
